import { isAndroid, isIos } from "@luma-team/shared";
import { LuxLink } from "@lux/atoms/components/LuxLink";
import { LuxModalContainer } from "@lux/atoms/components/LuxModal";
import { GlowWalletIcon } from "@glow/react/components/GlowWalletIcon";
import AndroidIcon from "@lux/icons/brands/Android.svg";
import AppleIcon from "@lux/icons/brands/Apple.svg";
import GlowLogo from "@glow/react/icons/GlowLogo.svg";
import classNames from "classnames";
import type { NextPage } from "next";
import React, { useState } from "react";

import { SocialHead } from "@components/all-pages/SocialHead";
import { GlowButton } from "@components/atoms/GlowButton";
import { DownloadAppModal } from "@components/nav/DownloadAppModal";
import { SolanaPage } from "@components/nav/SolanaPage";
import { DESKTOP_DOWNLOADS } from "@utils/browser";
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from "@utils/download";
import { ResponsiveBreakpoint } from "@utils/StyleConstants";
import { WalletTheme } from "@glow/react/components/wallet-theme";

const Heading = () => {
  return (
    <div className="heading zm-container flex-center flex-column">
      <GlowLogo />
      <h2 className="text-center">
        <span>Your new favorite </span>
        <span>Solana wallet awaits.</span>
      </h2>

      <style jsx>{`
        .heading {
          :global(svg) {
            width: 99px;
            height: 46px;
            margin-top: 5rem;
            margin-bottom: 1.25rem;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              width: 47px;
              height: 22px;
              margin-top: 2.25rem;
              margin-bottom: 1rem;
            }
          }

          h2 {
            font-size: 4rem;
            line-height: 1;
            margin: 0;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              font-size: 3rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              font-size: 2.5rem;
              padding: 0 var(--side-padding);
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              font-size: 2rem;
              line-height: 1.1;
            }

            span {
              display: block;

              @media (max-width: ${ResponsiveBreakpoint.small}) {
                display: inline;
              }
            }
          }
        }
      `}</style>
    </div>
  );
};

const DownloadCategory = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="download-category flex-center flex-column">
      <h3 className={"m-0 p-0 theme-root"}>{title}</h3>
      <div className="desc mt-3 text-center">{description}</div>
      <div className="content mt-4">{children}</div>

      <style jsx>{`
        .download-category {
          border-radius: 2.5rem;
          background: var(--secondary-bg-color);
          overflow: hidden;
          padding-top: 4rem;
          width: calc(50% - 1rem);
          position: relative;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            width: 100%;
            padding-top: 2rem;

            &:first-child {
              margin-top: 3rem;
            }
          }

          h3 {
            font-size: 2.9rem;
            line-height: 97.5%;
            color: var(--brand-color);

            @supports (background-clip: text) or (-webkit-background-clip: text) {
              background-clip: text;
              -webkit-background-clip: text;
              color: transparent;
              line-height: 110%;
              display: block;
              transform: translateZ(-1px);
            }

            @supports (background-clip: text) or (-webkit-background-clip: text) {
              background-image: linear-gradient(
                90.93deg,
                #f080ff -2.9%,
                #bb2dc7 51.12%,
                #8505b1 104.03%
              );
            }

            @media (prefers-color-scheme: dark) {
              @supports (background-clip: text) or
                (-webkit-background-clip: text) {
                background-image: linear-gradient(
                  90.93deg,
                  #f080ff -2.9%,
                  #ce50d8 51.12%,
                  #c225f9 104.03%
                );
              }
            }

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              font-size: 1.75rem;
            }
          }

          .desc {
            font-size: 1.125rem;
            line-height: 133%;
            max-width: 260px;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              font-size: 1rem;
            }
          }
        }
      `}</style>
    </div>
  );
};

export const DownloadDesktopType = ({
  href,
  iconSrc,
  label,
}: {
  href: string;
  iconSrc: string;
  label: string;
}) => {
  return (
    <div className="desktop-type animated rounded">
      <LuxLink href={href} openInNewTab>
        <div className="inner flex-center flex-column">
          <div
            className="icon"
            style={{ backgroundImage: `url(${iconSrc})` }}
          />
          <div className="pill">{label}</div>
        </div>
      </LuxLink>

      <style jsx>{`
        .desktop-type {
          padding: 0.5rem;

          &:hover {
            background: var(--hover-bg-color);
          }

          .inner {
            color: var(--primary-color);
          }

          .icon {
            position: relative;
            width: 54px;
            height: 54px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              width: 42px;
              height: 42px;
            }
          }

          .pill {
            background: var(--primary-bg-color);
            padding: 0.5rem 0;
            border-radius: calc(var(--input-height) / 2);
            margin-top: 1rem;
            min-width: 5.1rem;
            text-align: center;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              font-size: 0.8rem;
              padding: 0.375rem 0;
              margin-top: 0.75rem;
              min-width: 4rem;
            }
          }
        }
      `}</style>
    </div>
  );
};

const DownloadCategoryBottom = ({
  height,
  imageSrc,
}: {
  height: number;
  imageSrc: { light: string; dark: string };
}) => {
  return (
    <div className="download-bottom">
      <style jsx>{`
        .download-bottom {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: ${height + "px"};
          background-position: center bottom;
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-image: url(${imageSrc.light});

          @media (prefers-color-scheme: dark) {
            background-image: url(${imageSrc.dark});
          }
        }
      `}</style>
    </div>
  );
};

const DownloadCategoryBottomQR = () => {
  return (
    <div className="download-qr flex-center-center">
      <div className="flex-center flex-column text-center fs-xxs">
        <img className="light-only" src="/download-qr.png" />
        <img className="dark-only" src="/download-qr-dark.png" />
        Scan me!
      </div>

      <style jsx>{`
        .download-qr {
          position: absolute;
          left: calc(50% + 74px);
          bottom: 100px;
          width: 127px;
          height: 142px;
          border-radius: 13px;
          overflow: hidden;
          border: 1px solid rgba(0, 0, 0, 0.16);
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
          background: var(--white);
          color: var(--black);

          @media (prefers-color-scheme: dark) {
            border: 1px solid rgba(255, 255, 255, 0.16);
            background: #000;
            color: var(--white);
          }

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            display: none;
          }

          img {
            width: 102px;
            height: 102px;
          }
        }
      `}</style>
    </div>
  );
};

const DownloadDesktop = () => {
  return (
    <DownloadCategory
      title="Desktop"
      description="Turn your favorite browser into an easy-to-use Solana wallet."
    >
      <div className="download-desktop flex-center flex-column">
        <div className="flex-center">
          {DESKTOP_DOWNLOADS.map((download) => (
            <DownloadDesktopType
              key={download.icon}
              href={download.href}
              iconSrc={`/download/${download.icon}`}
              label={download.label}
            />
          ))}
        </div>

        <DownloadCategoryBottom
          height={357}
          imageSrc={{
            light: "/download/desktop-light.png",
            dark: "/download/desktop-dark.png",
          }}
        />

        <div className="wallet-icon">
          <GlowWalletIcon theme={WalletTheme.Barney} size={28} />
        </div>
      </div>

      <style jsx>{`
        .download-desktop {
          width: 100%;
          padding-bottom: 400px;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            padding-bottom: 300px;

            :global(.download-bottom) {
              height: 270px;
            }
          }

          .wallet-icon {
            position: absolute;
            left: calc(50% + 115px);
            bottom: 320px;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              left: calc(50% + 87px);
              bottom: 235px;
              transform-origin: left top;
              transform: scale(0.8);
            }
          }
        }
      `}</style>
    </DownloadCategory>
  );
};

const DownloadMobile = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const downloadClick = () => {
    if (isIos(navigator.userAgent)) {
      window.open(IOS_DOWNLOAD_LINK, "_blank");
    } else if (isAndroid(navigator.userAgent)) {
      window.open(ANDROID_DOWNLOAD_LINK, "_blank");
    } else {
      setShowDownloadModal(true);
    }
  };

  return (
    <DownloadCategory
      title="Mobile"
      description="Use Glow Wallet and your favorite Web3 Apps on the go."
    >
      <div className="download-mobile flex-center flex-column">
        <div className={"flex-center mt-3 theme-root gap-3 theme-root"}>
          <GlowButton
            onClick={downloadClick}
            label="Get for iOS"
            icon={<AppleIcon />}
            color="brand"
            rounded
          />
          <GlowButton
            onClick={downloadClick}
            label="Get for Android"
            icon={<AndroidIcon />}
            color="brand"
            rounded
          />
        </div>
        <DownloadCategoryBottom
          height={414}
          imageSrc={{
            light: "/download/mobile-light.png",
            dark: "/download/mobile-dark.png",
          }}
        />
        <div className="wallet-icon">
          <GlowWalletIcon theme={WalletTheme.Barney} size={36} />
        </div>
        <DownloadCategoryBottomQR />
      </div>

      <LuxModalContainer
        variant="lux-alert"
        onHide={() => setShowDownloadModal(false)}
      >
        {showDownloadModal && <DownloadAppModal />}
      </LuxModalContainer>

      <style jsx>{`
        .download-mobile {
          width: 100%;
          padding-bottom: 390px;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            padding-bottom: 340px;

            :global(.download-bottom) {
              height: 300px;
              background-position: calc(50% + 12px) bottom;
            }

            :global(.luma-button) {
              font-size: var(--font-size-sm);
            }
          }

          .wallet-icon {
            position: absolute;
            left: calc(50% + 22px);
            bottom: 311px;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              left: calc(50% + 28px);
              bottom: 216px;
              transform-origin: left top;
              transform: scale(0.75);
            }
          }
        }
      `}</style>
    </DownloadCategory>
  );
};

const DownloadOptions = () => {
  return (
    <div
      className={classNames(
        "download-options zm-container flex spread theme-root"
      )}
    >
      <DownloadDesktop />
      <DownloadMobile />

      <style jsx>{`
        .download-options {
          margin-top: 4.4rem;
          padding: 0 var(--side-padding);

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            flex-direction: column-reverse;
            margin-top: 2.5rem;
          }
        }
      `}</style>
    </div>
  );
};

const Download: NextPage = () => {
  return (
    <SolanaPage title="Download" footer={{ border: false }}>
      <SocialHead
        subtitle="Download"
        canonicalPath="/download"
        description="Glow is a fast, elegant and friendly Solana wallet. Download it today for free on iOS, Chrome and more."
        imagePath="/social/download.png"
      />
      <Heading />
      <DownloadOptions />
    </SolanaPage>
  );
};

export default Download;
